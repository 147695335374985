.receipt {
  font-family: 'IBMPlexMono', sans-serif;
  background-color: #fff;
  font-size: 14px;
  line-height: 24px;
  padding: 24px 11px 32px 12px;
  min-height: 100vh;
  text-align: center;
  max-width: 375px;
  margin: 0 auto;

  &__wrapper {
    border-bottom: 1px dashed #202331;
    margin-bottom: 8px;

  }

  &__header-wrapper {
    text-align: center;
  }

  &__section {
    border-bottom: 1px dashed #202331;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
  }  

  &__wrapper-section {

    margin-left: 8px;
    margin-right: 8px;
  }

  &__text {
    margin-bottom: 8px;
  }

  &__text-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: left;
  }

  &__price {
    white-space: nowrap;
    text-align: end;
  }

  &__price-wrapper {
    text-align: end;
  }

  &__header {
    text-align: center;
    font-weight: bold;
  }

  &__logo-wrapper {
    margin-bottom: 8px;
  }
}
