@import "../../../../../../assets/styles/vars";

.usage-advices {
  background-color: #dceef7;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    align-items: center;
  }

  @media (min-width: $desktop-width) {
    border-radius: 24px;
    margin: 0 0px 24px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
  }

  &__text-wrapper {
    padding: 40px 16px 20px 16px;

    @media (min-width: $tablet-width) {
      max-width: 100%;
    }

    @media (min-width: $desktop-width) {
      padding: 64px 0 99px 112px;
      width: 100%;
    }
  }

  &__header {
    margin-bottom: 16px;

    @media (min-width: $tablet-width) {
      max-width: 100%;
    }

    @media (min-width: $desktop-width) {
      margin-bottom: 24px;
    }
  }

  &__list {
    padding-left: 18px;

    @media (min-width: $desktop-width) {
      padding-left: 20px;
    }
  }

  &__item {
    line-height: 24px;

    @media (min-width: $desktop-width) {
      line-height: 32px;
    }
  }
}
