@import "../../../../../../assets/styles/vars";

.advantages {
  background-color: rgba(39, 174, 96, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $tablet-width) {
    align-items: center;
  }

  @media (min-width: $desktop-width) {
    width: 50%;
    border-radius: 24px;
    padding-bottom: 24px;
  }

  &__text-wrapper {
    padding: 40px 16px 20px 16px;

    @media (min-width: $tablet-width) {
      max-width: 100%;
    }

    @media (min-width: $desktop-width) {
      padding: 64px 0 0;
      width: 440px;
    }
  }

  &__header {
    margin-bottom: 14px;

    @media (min-width: $desktop-width) {
      margin-bottom: 24px;
    }
  }

  &__list {
    padding-left: 18px;

    @media (min-width: $desktop-width) {
      padding-left: 20px;
    }
  }

  &__item {
    line-height: 24px;

    @media (min-width: $desktop-width) {
      line-height: 32px;
    }
  }
}
