@import '../../../../assets/styles/vars';

.works {
  &__wrapper {
    @media(min-width: $desktop-width) {
     display: flex;
     margin-bottom: 24px;
    }
  }
}
